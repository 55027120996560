<template>
  <div class="pb-10">
    <app-loader :value="$apollo.loading"/>
    <v-snackbar color="error" v-model="error">{{ errorMessage }}</v-snackbar>
    <v-tabs
        v-model="tab"
        v-if="product"
        grow
        centered
    >
      <v-tab>Details</v-tab>
      <v-tab>Pricing</v-tab>
      <v-tab>Images</v-tab>
      <v-tab>Assets</v-tab>
      <v-tab>Bookings</v-tab>
      <v-tab>Reviews</v-tab>

      <v-tab-item>
        <v-card flat tile class="mb-15">
          <nice-header>Product details for {{ product.name }}</nice-header>
          <v-card-text>
            <v-alert outlined type="warning" v-if="noActivePlans">
              You haven't yet added any price plans to this product.
            </v-alert>
            <v-form ref="form" v-model="valid">
              <v-row>
                <v-col cols="12" md="6" class="pb-0">
                  <text-field
                      id="product-name"
                      label="Product Name"
                      v-model="form.name"
                      :rules="[rules.required]"
                  ></text-field>
                </v-col>
                <v-col cols="12" md="6" class="pb-0" v-if="accountData">
                  <autocomplete
                      id="category"
                      label="Category"
                      v-model="form.category"
                      :allow-add="true"
                      :items="accountData.categories"
                      :rules="[rules.required]"
                  ></autocomplete>
                </v-col>
                <v-col cols="12" class="mt-0">
                  <v-textarea
                      id="description"
                      auto-grow
                      label="Description"
                      v-model="form.description"
                      rounded
                      outlined
                  ></v-textarea>
                </v-col>
                <v-col cols="12" md="6">
                  <v-select
                      rounded
                      outlined
                      label="Hire type"
                      :items="hireTypes"
                      v-model="form.hireType"
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-divider/>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Product turnaround</v-subheader>
                  <p class="muted pl-5">
                    Use the below fields if you need to allow additional team before/after hires
                  </p>
                </v-col>
                <v-col cols="3">
                  <v-text-field
                      label="Time needed before each booking"
                      v-model="form.preTurnaroundCount"
                      rounded
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-select
                      v-model="form.preTurnaroundUnit"
                      label="Units"
                      rounded
                      outlined
                      :items="turnaroundUnits"
                  ></v-select>
                </v-col>
                <v-col cols="3" offset="1">
                  <v-text-field
                      label="Time needed after each booking"
                      v-model="form.postTurnaroundCount"
                      rounded
                      outlined
                  ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <v-select
                      v-model="form.postTurnaroundUnit"
                      label="Units"
                      rounded
                      outlined
                      :items="turnaroundUnits"
                  ></v-select>
                </v-col>
                <v-col cols="6">
                  <v-switch
                      v-model="form.featured"
                      label="Featured product"
                      hint="Featured products appear on your store landing page"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn
                color="info"
                rounded
                outlined
                link
                :to="`/products/${$route.params.id}/create-order/`"
            >
              Create manual order
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                rounded
                outlined
                :loading="loading"
                :disabled="!valid"
                @click="saveChanges"
            >Save changes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card flat tile>
          <v-container>
            <v-data-table
                :headers="headers"
                :items="product.plans"
                sort-by="price"
            >
              <template v-slot:top>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Pricing plans for {{ product.name }}</v-toolbar-title>
                  <v-divider
                      class="mx-4"
                      inset
                      vertical
                  ></v-divider>
                  <v-spacer></v-spacer>
                  <pricing-plan-form :product="product" @refresh="refreshProduct"/>
                </v-toolbar>
              </template>
              <template v-slot:item.price="{ item }">
                {{ product.catalog.currency.symbol }} {{ item.price }}
              </template>
              <template v-slot:item.interval="{ item }">
                {{ item.interval }} {{ item.period.toLowerCase() }}<span
                  v-if="item.interval > 1">s</span>
              </template>
              <template v-slot:item.actions="{ item }">
                <archive-plan :plan="item" @archived="refreshProduct"></archive-plan>
              </template>
              <template v-slot:no-data>
                This product has no pricing plans attached yet... click the button above to create one
              </template>
            </v-data-table>
          </v-container>
        </v-card>

      </v-tab-item>

      <v-tab-item>
        <v-card flat tile>
          <v-row>
            <v-col cols="12">
              <v-card flat>
                <v-toolbar flat color="white">
                  <v-toolbar-title>Images for {{ product.name }}</v-toolbar-title>
                  <v-divider
                      class="mx-4"
                      inset
                      vertical
                  ></v-divider>
                </v-toolbar>
                <v-subheader>
                  Help your customers see what they are renting out by adding some images
                </v-subheader>
                <v-row class="justify-center align-center">
                  <v-col
                      v-for="(image, n) in product.images"
                      :key="n"
                      cols="2"
                  >
                    <v-hover v-slot:default="{ hover }">
                      <v-card flat tile :class="{ 'on-hover': hover }">
                        <v-fab-transition>
                          <v-btn
                              v-show="hover"
                              color="error"
                              fab
                              dark
                              small
                              absolute
                              top
                              right
                              @click="deleteImage(image.id)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </v-fab-transition>
                        <v-img
                            @error="reloadImages"
                            :src="image.url"
                            contain
                            :lazy-src="image.url"
                            aspect-ratio="1"
                        >

                          <template v-slot:placeholder>
                            <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                            >
                              <v-progress-circular indeterminate
                                                   color="grey lighten-5"></v-progress-circular>
                            </v-row>
                          </template>

                        </v-img>
                      </v-card>
                    </v-hover>
                  </v-col>
                </v-row>
                <file-uploader @uploaded="refreshProduct" :product-id="product.id"/>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card
            flat
            tile
        >
          <v-card flat tile>
            <v-container>
              <v-data-table
                  :headers="assetHeaders"
                  :items="product.assets"
                  sort-by="sku"
              >
                <template v-slot:top>
                  <nice-header>
                    Assets for {{ product.name }}

                    <template v-slot:extra>
                      <v-spacer></v-spacer>
                      <add-assets :product="product" @refresh="refreshProduct"/>
                    </template>
                  </nice-header>
                </template>
                <template v-slot:item.actions="{ item }">
                  <edit-asset :asset="item" @save="refreshProduct"/>
                  <archive-asset :asset="item" @archived="refreshProduct"/>
                </template>
              </v-data-table>
            </v-container>
          </v-card>
        </v-card>
      </v-tab-item>

      <v-tab-item>
        <v-card
            flat
            tile
        >
          <product-booking-table :product="product"></product-booking-table>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <v-card
            flat
            tile
        >
          <rating-table :product="product"></rating-table>
        </v-card>
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import {user, product, accountData, deleteImage, editProduct} from '@/graphql'
import {stores} from '@/graphql'
import {mapState} from 'vuex'

export default {
  data: () => ({
    tab: null,
    deletePlan: false,
    editPlan: false,
    valid: null,
    loading: false,
    error: false,
    errorMessage: null,
    hireTypes: [
      {text: 'Collection from store', value: 'collection'},
      {text: 'Delivery', value: 'delivery'},
      {text: 'Both', value: 'both'}
    ],
    rules: {
      required: v => !!v || 'This field is required',
      numeric: v => !isNaN(v) || 'Must be a number',
    },
    turnaroundUnits: [
      'minutes', 'hours', 'days'
    ],
    form: {
      name: null,
      category: null,
      description: null,
      featured: false,
      hireType: null,
      preTurnaroundCount: 0,
      preTurnaroundUnit: 'minutes',
      postTurnaroundCount: 0,
      postTurnaroundUnit: 'minutes',
    },
    headers: [
      {text: 'Price', value: 'price'},
      {text: 'Interval', value: 'interval'},
      {text: 'Delete', value: 'actions', sortable: false},
    ]
  }),
  apollo: {
    user,
    accountData,
    stores,
    product: {
      query: product,
      variables() {
        return {pk: this.$route.params.id}
      }
    },
  },
  watch: {
    product(prod) {
      this.form = prod
    }
  },
  computed: {
    ...mapState(['selectedStore']),
    store() {
      if (this.selectedStore) {
        return this.stores.find(store => store.hashId === this.selectedStore)
      }
      return null
    },
    assetHeaders() {
      if (this.store) {
        return [
          {text: 'SKU', value: 'sku'},
          {text: this.store.labelOne, value: 'size'},
          {text: this.store.labelTwo, value: 'gender'},
          {text: this.store.labelThree, value: 'brand'},
          {text: 'Actions', value: 'actions', sortable: false}
        ]
      }
      return []
    },
    noActivePlans() {
      return this.product.plans.filter(plan => !plan.archived).length === 0
    }
  },
  methods: {
    refreshProduct() {
      this.$apollo.queries.product.refetch()
    },
    reloadImages() {
      var self = this;
      setTimeout(self.refreshProduct, 1000);
    },
    deleteImage(image) {
      this.$apollo.mutate({
        mutation: deleteImage,
        variables: {image}
      }).then(() => {
        this.refreshProduct()
      })
    },
    saveChanges() {
      this.loading = true;
      this.$apollo.mutate({
        mutation: editProduct,
        variables: {
          product: this.product.id,
          ...this.form
        }
      }).then(() => {
        this.refreshProduct();
        this.loading = false
      }).catch((error) => {
        this.error = true;
        this.errorMessage = error.message;
        this.loading = false
      })
    }
  }
}

</script>

